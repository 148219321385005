<template>
  <v-row no-gutters>
    <v-col cols="12" md="10" class="offset-md-1 text-center mt-12">
      <span class="catamaran font-weight-bold">{{$t('account.login.labels.social_alternatives')}} </span>
    </v-col>

    <!-- Social login buttons -->
    <v-col cols="12" class="text-center mt-3">

      <v-btn color="#1877f2" @click="loginFacebook" style="min-width: 32px !important;" class="mx-2">
        <v-icon color="white">fab fa-facebook-f</v-icon>
      </v-btn>

      <v-btn @click="loginGoogle" icon class="mx-2">
        <v-img width="32" src="@/assets/icons/controls/g-logo.png" />
      </v-btn>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "LoginSocialButtons",

  data() {
    return {
      gAuth: null
    }
  },

  mounted() {
    window.fbAsyncInit = function() {
      FB.init({
        appId      : process.env.VUE_APP_FACEBOOK_LOGIN_ID,
        cookie     : true,  // enable cookies to allow the server to access the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v8.0', // use graph api version 2.8

      });
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.gapi.load('auth2', () => {
      this.gAuth = window.gapi.auth2.init({client_id: process.env.VUE_APP_GOOGLE_ID, scope: "profile email"});
    });
  },

  methods: {
    loginFacebook() {
      window.FB.login(response => {
        console.log(response);
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;

          this.$http.post('/map/auth/facebook', {
            accessToken: accessToken
          })
            .then(res => {
              this.socialLoginCallback(res, true);
            })
            .catch(err => {
              this.socialLoginCallback(err, false);
            });
        }
        else
          this.$store.commit("toast/showError", this.$t('account.login.errors.FACEBOOK_ERROR'));
      }, {
        //scope: "id,email,first_name,last_name",
        scope: "email,public_profile",
        return_scopes: true
      })
    },

    loginGoogle() {

      if (this.gAuth) {
        this.gAuth.signIn()
          .then((res) => {
            this.$http.post('/map/auth/google', {
              accessToken: res.tc.id_token
            })
              .then(res => {
                this.socialLoginCallback(res, true);
              })
              .catch(err => {
                this.socialLoginCallback(err, false);
              });
          })
          .catch(err => {
            console.error(err);
            if (err)
              this.$store.commit("toast/showError", this.$t('account.login.errors.GOOGLE_ERROR'));
          });
      }
      else
        this.$store.commit("toast/showError", this.$t('account.login.errors.GOOGLE_ERROR'));
    },


    socialLoginCallback(res, isSuccess) {
      if (isSuccess) {
        // OK
        if (res.status === 200) {
          this.$session.start();

          this.$session.set('jwt', res.data.token);

          if (res.data.user._id)
            this.$session.set('id', res.data.user._id);

          this.$session.set("person.firstname", res.data.user.person.firstname);
          this.$session.set("person.name", res.data.user.person.name);
          this.$session.set("email", res.data.user.email);
          this.$session.set("plan", res.data.subscription.plan);
          return this.$router.push({name: "Home"});

        }
      }
      else {
        this.$store.commit('alert/showError', this.$t('account.login.errors.LOGIN_NOT_VALID'));
      }
    }
  }
}
</script>

<style scoped>

</style>